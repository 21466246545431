<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-sm-6 col-md-5">
        <b-card>
          <div class="table-responsive">
            <div class="example-avatar">
              <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active"></div>
              <div class="avatar-upload" v-show="!edit">
                <div class="text-center p-2">
                  <label for="avatar">
                    <img :src="files.length ? files[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'" width="190" height="190" style="padding-left: 10px; padding-right: 10px;" />
                  </label>
                </div>

                <div class="text-center p-2">
                  <file-upload
                    extensions="gif,jpg,jpeg,png,webp"
                    accept="image/png, image/gif, image/jpeg, image/webp"
                    name="avatar"
                    class="btn btn-primary"
                    :custom-action="customUpload"
                    :drop="!edit"
                    v-model="files"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload"
                  >Upload Image</file-upload>
                </div>
              </div>

              <div class="avatar-edit" v-show="files.length && edit">
                <div class="avatar-edit-image" v-if="files.length">
                  <img ref="editImage" :src="files[0].url" />
                </div>
                <div class="text-center p-4">
                  <button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear">Cancel</button>
                  <button type="submit" class="btn btn-primary" @click.prevent="editSave">Save</button>
                </div>
              </div>
            </div>

            <table class="table3">
              <tr>
                <td>ID</td>
                <td>{{rowData.id}}</td>
              </tr>
              <tr>
                <td>{{ $t('message.machineName') }}</td>
                <td>
                  <b-form-input type="text" v-model="rowData.name" :disabled="!isAdmin(role_id)"></b-form-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.machineSerial') }}</td>
                <td>
                  <b-form-input type="text" v-model="rowData.serial" :disabled="!isAdmin(role_id)"></b-form-input>
                </td>
              </tr>
              <tr v-if="isAdmin(role_id)">
                <td>{{ $t('message.simSerial') }}</td>
                <td>
                  <!-- <b-form-select v-model="rowData.simId" :options="simList" :disabled="!isAdmin(role_id)" text-field="serial" value-field="id"></b-form-select> -->
                  <b-form-select v-model="rowData.simId" :options="simList" :disabled="!isAdmin(role_id)" text-field="text" value-field="id"></b-form-select>
                </td>
              </tr>
              <tr v-if="isAdmin(role_id)">
                <td>{{ $t('message.gsmSerial') }}</td>
                <td>
                  <b-form-input type="text" v-model="rowData.gsmSerial" :disabled="!isAdmin(role_id)"></b-form-input>
                </td>
              </tr>
              <tr v-if="isAdmin(role_id)">
                <td>{{ $t('message.kioskOwner') }}</td>
                <td>
                  <b-form-select v-model="rowData.userId" :options="userList" :disabled="!isAdmin(role_id)" text-field="userName" value-field="userId"></b-form-select>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.status') }}</td>
                <template v-if="now - new Date(rowData.lastOnline) <= 1800000">
                  <td>
                    <span class="badge badge-success" style="font-size: 100%">{{ $t('message.online') }}</span>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.offline') }}</span>
                  </td>
                </template>
              </tr>
              <tr>
                <td>{{ $t('message.machineModel') }}</td>
                <td>{{rowData.model.name}}</td>
              </tr>
              <tr v-if="isAdmin(role_id)">
                <td>{{ $t('message.description') }}</td>
                <td>{{rowData.model.description}}</td>
              </tr>
              <tr v-if="isAdmin(role_id)">
                <td>{{ $t('message.slot') }}</td>
                <td>{{rowData.slotAmount}}</td>
              </tr>
              <!-- <tr v-if="isAdmin(role_id)">
              <td>{{ $t('message.fee') }} (%)</td>
              <td>
                  <b-form-select v-model="rowData.deductCredit.deductCreditType" :options="$t('message.arrays.fee')" class="mb-3" :disabled="!isAdmin(role_id)"></b-form-select>
                <div v-if="rowData.deductCredit.deductCreditType === 'AMOUNT_PER_UNIT'">
                  <b-form-input v-model.number="rowData.deductCredit.deductCreditValue"  type="number"></b-form-input>
                </div>
              </td>
              </tr>-->
              <!-- <tr v-if="isAdmin(role_id)">
              <td>Request password</td>
              <td><c-switch type="text" variant="pill" label color="success" size="lg" v-model="rowData.rqPassword" :disabled="!isAdmin(role_id)"/></td>
              </tr>-->
              <tr v-if="isAdmin(role_id)">
                <td>Payment Gateway</td>
                <td>
                  <b-form-select v-model="rowData.paymentGateway" :disabled="!isAdmin(role_id)">
                    <b-form-select-option value="LUGENT">Lugent</b-form-select-option>
                    <b-form-select-option value="KSHER">Ksher</b-form-select-option>
                  </b-form-select>
                </td>
              </tr>
              <tr v-if="rowData.paymentGateway == 'KSHER'">
                <td>Ksher Online</td>
                <td>
                  Merchant ID: {{ksherKey.online.appId}}, Casher ID: {{ksherKey.online.operatorId}}
                  <b-button v-if="isAdmin(role_id)" @click="showKsherModel('online')" size="sm" variant="primary">{{$t('message.setting')}}</b-button>
                </td>
              </tr>
              <tr v-if="rowData.paymentGateway == 'KSHER'">
                <td>Ksher Offline</td>
                <td>
                  Merchant ID: {{ksherKey.offline.appId}}, Casher ID: {{ksherKey.offline.operatorId}}
                  <b-button v-if="isAdmin(role_id)" @click="showKsherModel('offline')" size="sm" variant="primary">{{$t('message.setting')}}</b-button>
                </td>
              </tr>
            </table>
          </div>
        </b-card>

        <b-modal ref="ksherModel" hide-header @ok="updateKsherKey()" ok-title="ยืนยัน" close-title="ยกเลิก">
          <Form :model="formItem" :label-width="160">
            <FormItem label="Type">
              <template v-if="ksherType == 'online'">
                <td>
                  <span class="badge badge-success" style="font-size: 100%">{{ $t('message.online') }}</span>
                </td>
              </template>
              <template v-else>
                <td>
                  <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.offline') }}</span>
                </td>
              </template>
            </FormItem>
            <FormItem label="Merchant ID">
              <Input v-model="formItem.appId" placeholder="Enter Ksher Merchant ID"></Input>
            </FormItem>
            <FormItem label="Cashier ID">
              <Input v-model="formItem.operatorId" placeholder="Enter Ksher Cashier ID"></Input>
            </FormItem>
            <FormItem label="PrivateKey (.pem)">
              <Upload action :before-upload="handlePrivateKey">
                <Button icon="ios-cloud-upload-outline">Select the file to upload</Button>
              </Upload>
              <div v-if="keyFileName !== null">{{ keyFileName }}</div>
            </FormItem>

            <template v-if="ksherType == 'online'">
              <span v-if="this.statusKsherOnline == true"><center>{{$t('message.fileUploaded')}}</center></span>
              <span v-else><center>{{ $t('message.fileUploadedNotFound') }}</center></span>
            </template>
            <template v-else>
              <span v-if="this.statusKsherOffline == true"><center>{{$t('message.fileUploaded')}}</center></span>
              <span v-else><center>{{ $t('message.fileUploadedNotFound') }}</center></span>
            </template>
          </Form>
        </b-modal>

        <b-card header="Configs">
          <div class="table-responsive">
            <table class="table3">
              <thead>
                <th></th>
                <th>NAME</th>
                <th>VALUE</th>
              </thead>
              <tr v-if="isAdmin(role_id)">
                <td>1</td>
                <td style="text-align: left">REQUEST PASSWORD</td>
                <td>
                  <c-switch type="text" variant="pill" label color="success" size="lg" v-model="rowData.rqPassword" :disabled="!isAdmin(role_id)" />
                </td>
              </tr>

              <tr v-for="(items, index) in rowData.configs" v-bind:key="index">
                <td v-if="isAdmin(role_id)">{{index+2}}</td>
                <td v-if="!isAdmin(role_id)">{{index+1}}</td>
                <td style="text-align: left">{{items.name}}</td>
                <template v-if="items.type === 'BOOL'">
                  <td>
                    <c-switch type="text" variant="pill" label color="success" size="lg" v-model="items.value" :disabled="!isAdmin(role_id)" />
                  </td>
                </template>
                <template v-else-if="items.type === 'NUMBER'">
                  <td width="50%">
                    <b-form-input type="number" v-model="items.value " :disabled="!isAdmin(role_id)" min="0" />
                  </td>
                </template>
              </tr>
            </table>
          </div>
        </b-card>

        <b-card header="Statuses" v-if="isAdmin(role_id)">
          <table class="table3">
            <thead>
              <th>NAME</th>
              <th>VALUE</th>
            </thead>
            <tr v-for="(items, index) in rowData.statuses" v-bind:key="index">
              <template v-if="items.name === 'LOCATION'">
                <td>{{items.name}}</td>
                <td>
                  <b-button @click="showMap()" variant="primary">Show</b-button>
                </td>
              </template>
              <template v-else>
                <td>{{items.name}}</td>
                <template v-if="items.type === 'JSON' && items.value.status === 'available'">
                  <td>
                    <b-btn id="exPopover" variant="outline-success" size="sm">{{items.value.status}}</b-btn>
                  </td>
                </template>
                <template v-else-if="items.type === 'JSON' && items.value.status === 'error'">
                  <td>
                    <div>
                      <b-popover target="exPopover" :triggers="triggers" :content="items.value.details" placement="right">
                        <b-btn id="exPopover" variant="outline-danger" size="sm">{{items.value.status}}</b-btn>
                      </b-popover>
                    </div>
                  </td>
                </template>
                <template v-else-if="items.name === 'FILTER_DETAILS'">
                  <td>
                    <b-button variant="warning" size="sm" @click="showFilterDetails(JSON.parse(items.value))">แสดงรายละเอียด</b-button>
                  </td>
                </template>
                <template v-else>
                  <td>{{items.value}}</td>
                </template>
              </template>
            </tr>
          </table>
        </b-card>
      </div>
      <div class="col-md-7">
        <b-card header="Money inbox">
          <table class="table-modal" style="text-align: center">
            <thead style="text-align-last: center">
              <th>{{ $t('message.type') }}</th>
              <th>{{ $t('message.quantity') }}</th>
              <th>{{ $t('message.balance') }}</th>
            </thead>
            <tbody>
              <tr>
                <th>{{ $t('message.coin') }}</th>
                <td></td>
                <td></td>
              </tr>

              <tr v-for="(value, key) in rowData.inbox.coin" v-bind:key="key">
                <td>{{key}} {{ $t('message.currency') }}</td>
                <td>{{value}}</td>
                <td>{{value*key}} {{ $t('message.currency') }}</td>
              </tr>

              <tr>
                <th>{{ $t('message.banknote') }}</th>
                <td></td>
                <td></td>
              </tr>

              <tr v-for="(value, key) in rowData.inbox.bill" v-bind:key="key">
                <td>{{key}} {{ $t('message.currency') }}</td>
                <td>{{value}}</td>
                <td>{{value*key}} {{ $t('message.currency') }}</td>
              </tr>
            </tbody>
            <tfoot style="text-align-last: center">
              <td></td>
              <th>{{ $t('message.sum') }}</th>
              <th>{{rowData.inbox.total}} {{ $t('message.currency') }}</th>
            </tfoot>
          </table>
        </b-card>

        <b-card header="Coin Acceptor" v-if="isAdmin(role_id)">
          <div class="table-responsive">
            <table class="table3">
              <th colspan="5">
                Model {{rowData.coinAcceptor.model}}
                <b-button @click="showCoin()" variant="warning" size="sm" :disabled="!isAdmin(role_id)">{{ $t('message.changeCoinAcceptor') }}</b-button>
              </th>
              <tr>
                <th>{{ $t('message.type') }}</th>
                <th>1 {{ $t('message.currency') }}</th>
                <th>2 {{ $t('message.currency') }}</th>
                <th>5 {{ $t('message.currency') }}</th>
                <th>10 {{ $t('message.currency') }}</th>
              </tr>

              <tr v-for="(items, index) in rowData.coinAcceptor.configs" v-bind:key="index">
                <td>{{items.name}}</td>

                <td v-for="(data, idx) in items.value" v-bind:key="idx">
                  <template v-if="data.type === 'int'">
                    <b-form-input type="number" v-model.number="data.value" size="sm" :disabled="!isAdmin(role_id)" />
                  </template>
                  <template v-else-if="data.type == 'bool'">
                    <td>
                      <c-switch type="text" variant="pill" label color="success" size="lg" v-model="data.value" :disabled="!isAdmin(role_id)" />
                    </td>
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </b-card>
        <b-modal ref="changeModelCoinAcc" hide-header @ok="changeModelCoin()" ok-title="ยืนยัน" close-title="ยกเลิก">
          <p style="color: red">คำเตือน!! เมื่อเลือกโมดูลใหม่และกดยืนยันแล้วจะไม่สามารถนำข้อมูลเดิมกลับมาได้</p>
          <table class="table3">
            <tr>{{ $t('message.selectCoinAcceptor') }}</tr>
            <tr>
              <td>
                <b-form-select v-model="selectedCoinAcceptor" :options="coinAcceptorList" class="mb-3" text-field="model" value-field="value" />
              </td>
            </tr>
          </table>
        </b-modal>

        <b-card header="Bill Acceptor" v-if="isAdmin(role_id)">
          <div class="table-responsive">
            <table class="table3">
              <th colspan="5">
                Model {{rowData.billAcceptor.model}}
                <b-button @click="showBill()" variant="warning" size="sm" :disabled="!isAdmin(role_id)">{{ $t('message.changeBanknoteAcceptor') }}</b-button>
              </th>
              <tr>
                <th>{{ $t('message.type') }}</th>
                <th>20 {{ $t('message.currency') }}</th>
                <th>50 {{ $t('message.currency') }}</th>
                <th>100 {{ $t('message.currency') }}</th>
                <th>500 {{ $t('message.currency') }}</th>
                <th>1000 {{ $t('message.currency') }}</th>
              </tr>

              <tr v-for="(items, index) in rowData.billAcceptor.configs" v-bind:key="index">
                <td>{{items.name}}</td>
                <td v-for="(data, idx) in items.value" v-bind:key="idx">
                  <template v-if="data.type == 'int'">
                    <b-form-input type="number" v-model.number="data.value" size="sm" :disabled="!isAdmin(role_id)" />
                  </template>
                  <template v-else-if="data.type == 'bool'">
                    <td>
                      <c-switch type="text" variant="pill" label color="success" size="lg" v-model="data.value" :disabled="!isAdmin(role_id)" />
                    </td>
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </b-card>
      </div>
      <b-modal ref="changeModelBillAcc" hide-header @ok="changeModelBill()" ok-title="ยืนยัน" close-title="ยกเลิก">
        <p style="color: red">คำเตือน!! เมื่อเลือกโมดูลใหม่และกดยืนยันแล้วจะไม่สามารถนำข้อมูลเดิมกลับมาได้</p>
        <table class="table3">
          <tr>{{ $t('message.selectBanknoteAcceptor') }}</tr>
          <tr>
            <td>
              <b-form-select v-model="selectedBillAcceptor" :options="billAcceptorList" class="mb-3" text-field="model" value-field="value" />
            </td>
          </tr>
        </table>
      </b-modal>
      <b-modal ref="mapModal" hide-header hide-footer size="lg">
        <template v-if="locationData.lat && locationData.lon">
          <gmap-map :center="locationData" :zoom="18" style="width: 770px; height: 600px">
            <gmap-marker :position="locationData" :clickable="true" :draggable="true"></gmap-marker>
          </gmap-map>
        </template>
      </b-modal>
    </div>

    <!-- start kan -->
    <b-card header="Devices">
      <div>
        <b-btn v-on:click="showModalDevice" variant="success" :disabled="!isAdmin(role_id)">{{ $t('message.addDevice') }}</b-btn>&nbsp;
      </div>
      <!-- <hr /> -->
      <br />
      <template>
        <div class="table-responsive">
          <table class="table table-striped table2">
            <thead>
              <th style="vertical-align: middle;">ID</th>
              <th style="vertical-align: middle;">{{ $t('message.enable') }}</th>
              <th style="vertical-align: middle;">{{ $t('message.status') }}</th>
              <th style="vertical-align: middle;">{{ $t('message.machineType') }}</th>
              <th style="vertical-align: middle;">{{ $t('message.size') }}</th>
              <th style="vertical-align: middle;">{{ $t('message.setting') }}</th>
            </thead>
            <tbody>
              <tr v-for="d in rowData.devices" v-bind:key="d.id">
                <td style="vertical-align: middle;">{{d.id}}</td>
                <!-- <td valign="middle"><c-switch type="text" variant="pill" label color="success" size="lg" v-model="d.enable" :disabled="!isDealer(role_id)"/></td> -->
                <td valign="middle" style="vertical-align: middle;">
                  <c-switch type="text" variant="pill" label color="success" size="lg" v-model="d.enable" />
                </td>

                <td style="vertical-align: middle;">
                  <template v-if="(d.status) === 'WASHING'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.washing') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'READY'">
                    <span class="badge badge-success" style="font-size: 100%">{{ $t('message.ready') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'ERROR'">
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.machineError') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'UNKNOW'">
                    <span class="badge badge-secondary" style="font-size: 100%">{{ $t('message.deviceStatus.UNKNOW') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'MC_FAILED'">
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.deviceStatus.MC_FAILED') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'COMMU_FAILED'">
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.deviceStatus.COMMU_FAILED') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'MC_NOT_SUPPORT'">
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.deviceStatus.MC_NOT_SUPPORT') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_POWERUP'">
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_POWERUP') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_WAIT_CLOSING_DOOR'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_WAIT_CLOSING_DOOR') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_DOOR_OPEN'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_DOOR_OPEN') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_WAIT_USER'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_WAIT_USER') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_WASH'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_WASH') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_RINSE'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_RINSE') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_SPIN'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_SPIN') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_DRYING'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_DRYING') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_COOL_DOWN'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_COOL_DOWN') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'BUSY_DRYER_EXTENSION'">
                    <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.deviceStatus.BUSY_DRYER_EXTENSION') }}</span>
                  </template>

                  <template v-else-if="(d.status) === 'CLOSE_SERVICE'">
                    <span class="badge badge-secondary" style="font-size: 100%">{{ $t('message.deviceStatus.CLOSE_SERVICE') }}</span>
                  </template>

                  <template v-else>
                    <span class="badge badge-secondary" style="font-size: 100%">UNKNOW</span>
                  </template>
                </td>

                <td style="vertical-align: middle;">
                  <b-form-select v-model="d.deviceType" :options="deviceType" text-field="name" value-field="typeId" :disabled="!isAdmin(role_id)" style="width: auto;" />
                </td>
                <td style="vertical-align: middle; ">
                  <b-form-input type="text" v-model="d.description" :disabled="!isAdmin(role_id)" style="width: auto;" />
                </td>

                <td class="col-12 col-md-12 col-lg-2">
                  <b-btn style="width: 60px; margin-right: 10px;" variant="warning" @click="showModal(d)">{{ $t('message.setting') }}</b-btn>
                  <!-- &nbsp; -->
                  <b-btn style="width: 60px;" class="my-1" v-if="isAdmin(role_id)" variant="danger" @click="showModalDeleteDevice(d)" :disabled="!isAdmin(role_id)">{{ $t('message.delete') }}</b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </b-card>

    <div>
      <!-- Modal Component -->
      <b-modal ref="myModalRef" size="xl" hide-header @ok="createNewSlots()" :ok-title="$t('message.save')" :cancel-title="$t('message.cancel')" @cancel="getMachineDetails()">
        <template>
          <b-button v-if="isAdmin(role_id)" variant="success my-1" @click="addLineSlots()" :disabled="!isAdmin(role_id)">{{ $t('message.add') }}</b-button>&nbsp;
          <hr />
          <template v-if="modalData.deviceDetails.type !== 'board'">
            <thead align="center">
              <th>{{ $t('message.slot') }}</th>
              <th>{{ $t('message.price') }}</th>
              <th v-if="modalData.deviceDetails.type === 'vending'">{{ $t('message.remainingAmount') }}</th>
              <th v-if="isAdmin(role_id)">{{ $t('message.enable') }}</th>
              <th>{{ $t('message.product') }}</th>
            </thead>
            <tr align="center" v-for="(items, index) in modalData.slots" v-bind:key="index">
              <td width="10%">
                <b-form-input type="number" v-model.number="items.slotId" :disabled="!isAdmin(role_id)" />
              </td>
              <td width="10%">
                <b-form-input type="number" v-model.number="items.price" />
              </td>
              <td width="10%" v-if="modalData.deviceDetails.type === 'vending'">
                <b-form-input type="number" v-model.number="items.qty" />
              </td>
              <td valign="middle" width="10%" v-if="isAdmin(role_id)">
                <c-switch type="text" variant="pill" label color="success" size="lg" v-model="items.enable" />
              </td>
              <td>
                <b-form-select v-model.number="items.productId" :options="productList" text-field="name" value-field="id" :disabled="(!isAdmin(role_id) && modalData.deviceDetails.type !== 'vending')" />
              </td>
              <td v-if="isAdmin(role_id)">
                <b-button variant="danger" @click="removeLineSlots(items)" :disabled="index === 0 || !isAdmin(role_id)">{{ $t('message.delete') }}</b-button>
              </td>
            </tr>
          </template>
          <template v-else>
            <div class="table-responsive">
              <table class="table2">
                <thead style="text-align-last: center">
                  <th>{{ $t('message.slot') }}</th>
                  <th>{{ $t('message.note') }}</th>
                  <th v-if="isAdmin(role_id)">{{ $t('message.enable') }}</th>
                  <template v-for="(item, index) in modalData.slots">
                    <template v-if="item.config" v-for="(items, indexConfig) in item.config">
                      <th v-if="index === 0" colspan="2">{{ $t('message.period') }} {{indexConfig+1}}</th>
                    </template>
                  </template>
                </thead>
                <tr align="center" v-for="(items, index) in modalData.slots" v-bind:key="index">
                  <td width="10%">
                    <b-form-input type="number" v-model.number="items.slotId" />
                  </td>
                  <td>
                    <b-form-input type="text" v-model="items.note" />
                  </td>
                  <td valign="middle" width="10%" v-if="isAdmin(role_id)">
                    <c-switch type="text" variant="pill" label color="success" size="lg" v-model="items.enable" />
                  </td>
                  <template v-for="(item, index) in items.config">
                    <td>
                      <datetime type="time" v-model="item.start" input-class="form-control" format="HH:mm"></datetime>
                    </td>
                    <td>
                      <datetime type="time" v-model="item.stop" input-class="form-control" format="HH:mm"></datetime>
                    </td>
                  </template>
                  <template v-if="items.config.length < 4">
                    <td colspan="2">
                      <b-btn variant="success" size="sm" @click="addTime(items)">{{ $t('message.addTime') }}</b-btn>
                    </td>
                  </template>
                </tr>
              </table>
            </div>
          </template>
        </template>
      </b-modal>
    </div>

    <b-modal ref="modalDeleteDevice" size="sm" @ok="deleteDevice()" hide-header :ok-title="$t('message.confirm')" :cancel-title="$t('message.back')">
      <table class="table-modal">
        <tr>
          <td>{{ $t('message.machineId') }}</td>
          <td style="color: red">{{modalDataDeleteDevice.machineId}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.deviceId') }}</td>
          <td style="color: red">{{modalDataDeleteDevice.id}}</td>
        </tr>
      </table>
    </b-modal>

    <b-modal ref="modalFilterDetails" size="md" hide-header>
      <table class="table-modal">
        <tr style="text-align: center">
          <th>Filter ID</th>
          <th>วันที่เปลี่ยนล่าสุด</th>
          <th>ปริมาณน้ำที่ไหลผ่าน</th>
        </tr>
        <tr v-for="items in modalFilterDetailsData" v-bind:key="items.id" style="text-align: center">
          <td>{{items.id}}</td>
          <td>{{items.lastChange}}</td>
          <td>{{items.qty}} ลิตร</td>
        </tr>
      </table>
    </b-modal>

    <!-- Modal Component -->
    <b-modal ref="myModalRefDevice" size="lg" hide-header @ok="createDevices()" @hide="getMachineDetails()" :ok-title="$t('message.create')" :cancel-title="$t('message.cancel')">
      <template>
        <div>
          <b-button variant="info" @click="addLine">{{ $t('message.add') }}</b-button>&nbsp;
          <b-button variant="info" @click="removeLine">{{ $t('message.delete') }}</b-button>&nbsp;
          <hr />
          <b-row class="text-center" v-for="line in lines" v-bind:key="line.id">
            <b-col cols="2">
              <b-form-input v-model="line.id" type="text" placeholder="id" />
            </b-col>
            <b-col cols="10">
              <b-form-select v-model="line.deviceType" :options="deviceType" text-field="name" value-field="typeId" />
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
    <!-- end kan -->

    <b-card>
      <div align="right">
        <b-button cols="1" md="6" lg="2" @click="goBack()" variant="secondary" size="lg">{{ $t('message.back') }}</b-button>&nbsp;
        <!-- <b-button @click="confirm()" variant="primary" size="lg" >บันทึก</b-button> -->
        <b-button cols="6" md="6" lg="2" @click="updateNewMachine()" variant="primary" size="lg">{{ $t('message.save') }}</b-button>
      </div>
    </b-card>
    <!-- <b-modal ref="confirmModal" hide-header size="sm" @ok="saveSlots()">
     Press OK to save
    </b-modal>-->
  </div>
</template>

<script>
import { Switch as cSwitch } from '@coreui/vue'
import webServices from '../../script'
import { DOMAIN } from '../../app.config'
import { buildFormData } from '../../lib/buildFormData'

import VueGoogleMaps from 'vue2-google-maps'
import { ModelListSelect } from '../../custom_modules/search-select'

import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
export default {
  name: 'setting',
  components: {
    cSwitch,
    VueGoogleMaps,
    ModelListSelect,
    FileUpload
  },
  data() {
    return {
      formData: new FormData(),
      formItem: {},
      keyFileName: null,
      files: [],
      edit: false,
      cropper: false,
      test: '',
      now: new Date(),
      role_id: window.localStorage.getItem('roleID'),
      userId: window.localStorage.getItem('userId'),
      id: this.$route.params.id,
      productList: [],
      coinAcceptorList: [],
      billAcceptorList: [],
      selectedCoinAcceptor: {
        model: '',
        configs: ''
      },
      statuses: [],
      selectedBillAcceptor: {},
      rowData: {
        id: this.$route.params.id,
        name: '',
        serial: '',
        mode: '',
        userId: '',
        slotAmount: '',
        coinAcceptor: {},
        billAcceptor: {},
        slots: [],
        model: {
          type: {
            name: ''
          }
        },
        configs: [],
        statuses: [],
        image: '',
        inbox: {
          coin: '',
          bill: '',
          total: ''
        },
        deductCredit: ''
      },
      productArg: {
        page: 1,
        rows: 50,
        sku: ''
      },
      locationData: {
        lat: 0,
        lng: 0
      },
      machineType: '',
      triggers: ['hover'],
      newSlots: [],
      //kan
      deviceType: [],
      modalData: {
        deviceDetails: {}
      },
      modalDataDevice: [],
      modalDataDeleteDevice: {},
      modalFilterDetailsData: [],
      lines: [],
      selected: null,
      options: [
        { value: 'AMOUNT_PER_UNIT', text: 'หักค่าทำเนียม' },
        { value: 'NONE', text: 'ไม่หักค่าทำเนียม' }
      ],
      //end kan
      simList: [],
      userList: [],
      ksherType: null,
      statusKsherOnline: false,
      statusKsherOffline: false,
      statusksherKey: false,
      ksherKey: {
        id: null,
        online: {
          appId: null,
          operatorId: null
        },
        offline: {
          appId: null,
          operatorId: null
        }
      }
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  methods: {
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      })
    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    async customUpload(file, component) {
      // var formData = new FormData()
      // formData.append('file', file.file)
      // formData.append('fileName', 'public/images/machines/' + this.id + '.' + file.file.name.split('.').pop())
      // formData.append('id', this.id)
      // formData.append('category', 'machine')
      // return await webServices.upload(formData)
      this.formData.append('file', file.file)
    },
    isAdmin(role) {
      return webServices.isAdmin(role)
    },
    isDealer(role) {
      return webServices.isDealer(role)
    },
    getMachineDetails() {
      this.$Progress.start()
      webServices
        .getMachineProfile(this.id)
        .then(res => {
          this.$Progress.finish()
          // if (res.data.machine.userId !== this.userId && this.role_id !== 'admin') {
          //     this.$toast.error({
          //       title: 'ERROR',
          //       message: 'คุณไม่ได้รับอนุญาติให้เข้าถึงหน้านี้'
          //     })
          //     this.goBack()
          // } else {
          this.rowData = res.data
          this.rowData.configs.forEach(val => {
            this.rowData.model.configs.forEach(model => {
              if (val.name === model.name) {
                val.type = model.type
              }
            })
          })
          for (var i = 0; i < this.rowData.configs.length; i++) {
            if ((this.rowData.configs[i].value === 'true' || this.rowData.configs[i].value === '1') && this.rowData.configs[i].type === 'BOOL') {
              // console.log(this.rowData.configs[i].value)
              this.rowData.configs[i].value = true
            } else if ((this.rowData.configs[i].value === 'false' || this.rowData.configs[i].value === '0') && this.rowData.configs[i].type === 'BOOL') {
              // console.log(this.rowData.configs[i].value)
              this.rowData.configs[i].value = false
            }
          }

          // const configs = []
          // this.rowData.configs.forEach(val => {
          //   if (val.name === 'ENABLE') {
          //     configs[0] = val
          //   } else if (val.name === 'RESET') {
          //     configs[1] = val
          //   } else if (val.name === 'SMS') {
          //     configs[2] = val
          //   } else if (val.name === 'CASH PAYMENT') {
          //     configs[3] = val
          //   } else if (val.name === 'PROMPT PAY') {
          //     configs[4] = val
          //   } else if (val.name === 'PAYMENT MENU PAGE') {
          //     configs[5] = val
          //   } else if (val.name === 'MENU WASHER') {
          //     configs[6] = val
          //   } else if (val.name === 'MENU DRY') {
          //     configs[7] = val
          //   } else if (val.name === 'MENU ADD CREDIT') {
          //     configs[8] = val
          //   } else if (val.name === 'MENU SALE') {
          //     configs[9] = val
          //   } else if (val.name === 'MENU LAUNDRY SERVICE') {
          //     configs[10] = val
          //   } else if (val.name === 'MENU USER SETTING') {
          //     configs[11] = val
          //   } else if (val.name === 'USER RESET PASSWORD') {
          //     configs[12] = val
          //   }
          // })
          // if (this.rowData.model.type === 'washing') {
          //   this.rowData.configs = configs
          // }

          for (var i = 0; i < this.rowData.statuses.length; i++) {
            if (this.rowData.statuses[i].name === 'LOCATION') {
              this.locationData = this.rowData.statuses[i].value
            }
          }
          this.machineType = res.data.model.type.name
          this.changeStatus()
          this.getDeviceType()

          if (this.rowData.image != null) {
            this.files[0] = { url: DOMAIN + '/' + this.rowData.image }
          } else {
            this.files[0] = { url: DOMAIN + '/public/images/machines/wonderwash.jpg' }
          }
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('ERROR @MACHINE DETAIL')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getProduct() {
      //console.log('get product')
      let params = {
        type: this.machineType,
        productType:
          this.modalData.deviceDetails.type === 'washer'
            ? 'washer'
            : this.modalData.deviceDetails.type === 'dryer'
            ? 'dryer,dryer_extension_tm'
            : this.machineType === 'washing' && this.modalData.deviceDetails.type === 'vending'
            ? `softener,detergent`
            : ''
      }
      webServices
        .getProductList(params)
        .then(res => {
          // console.log(res.data)
          this.productList = res.data
        })
        .catch(err => {
          console.log('ERROR @PRODUCT')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getCoin() {
      webServices
        .getCoin()
        .then(res => {
          this.coinAcceptorList = res.data
          for (var i = 0; i < this.coinAcceptorList.length; i++) {
            this.coinAcceptorList[i].model = this.coinAcceptorList[i].value.model
          }
        })
        .catch(err => {
          console.log('ERROR @COIN')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getBill() {
      webServices
        .getBill()
        .then(res => {
          this.billAcceptorList = res.data
          for (var i = 0; i < this.billAcceptorList.length; i++) {
            this.billAcceptorList[i].model = this.billAcceptorList[i].value.model
          }
        })
        .catch(err => {
          console.log('ERROR @BILL')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    changeModelCoin() {
      this.rowData.coinAcceptor.model = this.selectedCoinAcceptor.model
      this.rowData.coinAcceptor.configs = this.selectedCoinAcceptor.configs
    },
    showCoin() {
      this.$refs.changeModelCoinAcc.show()
    },
    changeModelBill() {
      this.rowData.billAcceptor.model = this.selectedBillAcceptor.model
      this.rowData.billAcceptor.configs = this.selectedBillAcceptor.configs
    },
    showBill() {
      this.$refs.changeModelBillAcc.show()
    },
    showMap() {
      this.$refs.mapModal.show()
    },
    showKsherModel(type) {
      this.ksherType = type
      this.formItem = { ...this.ksherKey[type] }
      this.$refs.ksherModel.show()
    },
    changeStatus() {
      this.statuses = this.rowData.statuses
      for (var i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].define && this.statuses[i].type === 'NUMBER' && this.statuses[i].value !== null) {
          var status = this.statuses[i].value.toString()
          this.statuses[i].value = this.statuses[i].define[status]
        }
      }
    },
    goBack() {
      window.history.back()
    },
    confirm() {
      this.$refs.confirmModal.show()
    },
    getDeviceType() {
      webServices
        .getDeviceType({ kiosktype: this.machineType })
        .then(res => {
          this.deviceType = res.data
          //console.log(this.deviceType)
        })
        .catch(err => {
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    showModal(data) {
      data.slots = data.slots.sort((a, b) => a.slotId - b.slotId)
      this.modalData = data
      this.$refs.myModalRef.show()
      this.getProduct()
    },
    showModalDevice(data) {
      this.modalDataDevice = data
      this.$refs.myModalRefDevice.show()
      //  console.log(this.modalDataDevice)
    },
    showModalDeleteDevice(data) {
      this.modalDataDeleteDevice = data
      this.$refs.modalDeleteDevice.show()
    },
    showFilterDetails(data) {
      this.modalFilterDetailsData = data
      this.$refs.modalFilterDetails.show()
    },
    deleteDevice() {
      const { machineId, deviceIndex, id } = this.modalDataDeleteDevice
      const body = {
        machineId: machineId,
        deviceIndex: deviceIndex,
        id: id
      }
      webServices
        .deleteDevice(body)
        .then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'device has been deleted'
            })
          } else {
            const error = webServices.showError(res.data)
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(error)
            })
          }
          this.getMachineDetails()
        })
        .catch(error => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    addLine() {
      let lastItem = Object.assign({}, this.rowData.devices[this.rowData.devices.length - 1])
      lastItem.id++
      delete lastItem.deviceIndex
      this.lines.push(lastItem)
      this.rowData.devices.push(lastItem)
    },
    removeLine() {
      this.lines.pop()
    },
    addLineSlots() {
      let lastItem = Object.assign({}, this.modalData.slots[this.modalData.slots.length - 1])
      lastItem.slotId++
      lastItem.config = [
        {
          start: '00:00',
          stop: '00:00'
        },
        {
          start: '00:00',
          stop: '00:00'
        },
        {
          start: '00:00',
          stop: '00:00'
        },
        {
          start: '00:00',
          stop: '00:00'
        }
      ]
      this.modalData.slots.push(lastItem)
      this.newSlots.push(lastItem)
    },
    removeLineSlots(slots) {
      const { machineId, deviceId, slotId } = slots
      const slot = {
        machineId: machineId,
        deviceId: deviceId,
        slotId: slotId
      }
      webServices
        .deleteSlots(slot)
        .then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'slot has been deleted'
            })
          } else {
            const error = webServices.showError(res.data)
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(error)
            })
          }
          this.getMachineDetails()
          this.modalData.slots = this.modalData.slots.filter(el => el.slotId !== slotId)
        })
        .catch(error => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    addTime(slot) {
      if (!slot.config) {
        slot.config = []
      }
      if (slot.config.length > 0) {
        slot.config.push({
          start: '00:00',
          stop: '00:00'
        })
      } else {
        slot.config.push(
          {
            start: '00:00',
            stop: '00:00'
          },
          {
            start: '00:00',
            stop: '00:00'
          },
          {
            start: '00:00',
            stop: '00:00'
          },
          {
            start: '00:00',
            stop: '00:00'
          }
        )
      }
    },
    createDevices() {
      this.$Progress.start()
      webServices
        .createDevices(this.lines)
        .then(res => {
          this.$Progress.finish()
          // console.log(res.data)
          if (res.data.success) {
            //location.reload();
            this.$toast.success({
              title: 'SUCCESS',
              message: 'device has been created'
            })
          } else {
            const error = webServices.showError(res.data)
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(error)
            })
          }
          this.lines = []
          this.getMachineDetails()
        })
        .catch(error => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    createNewSlots() {
      this.$Progress.start()
      webServices
        .updateSlots(this.modalData.slots)
        .then(resp => {
          this.$Progress.finish()
          if (resp.data.success) {
            if (this.newSlots.length > 0) {
              webServices
                .createSlots(this.newSlots)
                .then(res => {
                  if (res.data.success) {
                    //location.reload();
                    this.$toast.success({
                      title: 'SUCCESS',
                      message: 'สำเร็จ'
                    })
                  } else {
                    const error = webServices.showError(res.data)
                    this.$toast.error({
                      title: 'ERROR',
                      message: JSON.stringify(error)
                    })
                  }
                  this.getMachineDetails()
                  // this.updateNewMachine()
                })
                .catch(error => {
                  this.$Progress.fail()
                  console.log(error)
                  this.$toast.error({
                    title: 'ERROR',
                    message: 'เกิดข้อผิดพลาด'
                  })
                  if (err.response.status === 401) {
                    webServices.tokenExpire()
                  }
                  this.getMachineDetails()
                })
            }
            this.$toast.success({
              title: 'SUCCESS',
              message: 'บันทึกสำเร็จ'
            })
            this.newSlots = []
          } else {
            this.getMachineDetails()
            const errorText = {
              errorCode: resp.data.errorCode,
              errorText: resp.data.errorText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          }
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    updateNewMachine() {
      this.$Progress.start()

      buildFormData(this.formData, this.rowData)

      webServices
        .updateMachine(this.formData)
        .then(res => {
          this.$Progress.finish()
          if (res.data.success) {
            // console.log('success')
            var data = {
              machineId: this.id,
              cmdId: 1
            }
            webServices
              .setCommands(data)
              .then(resp => {
                this.$toast.success({
                  title: 'SUCCESS',
                  message: 'Machine has been updated'
                })
                // console.log(resp.data)
                // console.log('set commands')
                window.history.back()
              })
              .catch(err => {
                console.log('error @COMMANDS')
                console.log(err)
                this.$toast.error({
                  title: 'ERROR',
                  message: 'เกิดข้อผิดพลาด'
                })
                if (err.response.status === 401) {
                  webServices.tokenExpire()
                }
                this.getMachineDetails()
              })
          } else {
            this.getMachineDetails()
            const error = webServices.showError(res.data)
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(error)
            })
          }
        })
        .catch(err => {
          this.getMachineDetails()
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getSim() {
      webServices
        .getSim()
        .then(res => {
          this.simList = res.data
          this.simList = this.simList.reverse() // data reverse

          for (let i = 0; i < this.simList.length; i++) {
            if (this.simList[i].note == '') {
              this.simList[i].text = this.simList[i].provider + ' / ' + this.simList[i].serial + ' / --- NO NOTE --- '
            } else {
              this.simList[i].text = this.simList[i].provider + ' / ' + this.simList[i].serial + ' / ' + this.simList[i].note
            }
          }
          // console.log("simList", this.simList)
        })
        .catch(err => {
          console.log(err)
          console.log('error @sim')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getUserList() {
      webServices
        .getUserList('all')
        .then(res => {
          this.userList = res.data
        })
        .catch(err => {
          console.log(err)
          console.log('error @user')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getKsherKey() {
      this.$Progress.start()

      this.axios.get(`/payment/privatekey/${Number(this.id)}`).then(res => {
        console.log('logapi getKsherKey', res.data) 
        if (res.data.success) {
          this.statusksherKey = true
          console.log('statusksherKey', this.statusksherKey)

          if (res.data.data.online) {
            this.ksherKey.online = res.data.data.online
            this.statusKsherOnline = true
            console.log('statusKsherOnline', this.statusKsherOnline)
          }

          if (res.data.data.offline) {
            this.ksherKey.offline = res.data.data.offline
            this.statusKsherOffline = true
            console.log('statusKsherOffline', this.statusKsherOffline)
          }
        }

        this.$Progress.finish()
      })
    },
    handlePrivateKey(keyFile) {
      const reader = new FileReader()
      if (keyFile.name.includes('.pem')) {
        this.keyFileName = keyFile.name
        reader.onload = res => {
          this.formItem.privateKey = res.target.result
          // console.log(res.target.result)
        }
        reader.onerror = err => console.log(err)
        reader.readAsText(keyFile)
      } else {
        this.keyFileName = 'is not private key file (.pem)'
      }
      return false
    },
    updateKsherKey() {
      const params = {
        id: Number(this.id)
      }

      params[this.ksherType] = { ...this.formItem }

      this.ksherKey[this.ksherType] = { ...this.formItem }

      this.axios.post('/payment/privatekey', { ...params }).then(res => {
        if (res.data.success) {
          this.$toast.success({
            title: 'SUCCESS',
            message: 'Update Success'
          })
        } else {
          this.$toast.error({
            title: 'ERROR',
            message: 'Can not update Private key '
          })
        }
      })
    }
  },
  mounted() {
    if (this.id !== '') {
      this.getMachineDetails()
      this.getKsherKey()
      this.getCoin()
      this.getBill()
      this.getSim()
      this.getUserList()
    }
  }
}
</script>

<style lang="scss">
.table3 {
  width: 100%;
  border: none;
}
.table3 td,
.table3 th {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.example-avatar .avatar-upload .rounded {
  width: 200px;
  height: 200px;
}
.example-avatar .text-center .btn {
  margin: 0 0.5rem;
}
.example-avatar .avatar-edit-image {
  max-width: 100%;
}
.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-avatar .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
